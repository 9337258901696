/* HomePage.css */

/* Navbar styles remain the same */

.container {
    max-width: 80%
}

.homepage {
    background-color: #e8e8e5;
}

/* Home section styles */
.home-section {
    padding: 50px 20px;
    text-align: center;
}


.intro {
    background-color: #dededc; /* WHITE SMOKE */

}

.intro h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 50px;
}

.intro p {
    font-size: 1.5rem;
    margin-bottom: 20px;
    width: 100%;
}


.booking-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -80px;
}

.booking-option {
    width: 100%;
    max-width: 700px; /* Adjust as needed */
    background-color: rgba(187, 201, 201, 0.72); /* POWDER BLUE */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.container h2 {
    width: 100%;
    font-size: 2rem;
    /*remove space from top and add space at bottom*/
    padding: 0 0 20px 0;
}

/*footer*/
.footer {
    background-color: #f5f5f5; /* WHITE SMOKE */
    padding: 5px 0;
    /*width: 100%;*/
    margin: 0 auto;
}

.about {
    margin-top: -100px;
}

/*for small screen reduce slider and the images*/
@media screen and (max-width: 768px) {
    .booking-option {
        width: 100%;
        max-width: 100%;
    }

    .intro h2 {
        font-size: 2rem;
    }

    .intro p {
        font-size: 1.2rem;
    }

    .booking-options {
        margin-top: -50px;
    }

    .container h2 {
        font-size: 1.5rem;
    }

    .about {
        margin-top: -50px;
    }

}

/*for extra small screen reduce slider and the images*/
@media only screen and (max-width: 480px) {

    .home-section {
        padding: 20px 10px;
    }
    .booking-option {
        width: 100%;
        max-width: 100%;
    }

    .intro h2 {
        font-size: 2.5rem;
    }

    .intro p {
        font-size: 1rem;
    }

    .booking-options {
        margin-top: -20px;
    }

    .container h2 {
        font-size: 1.8rem;
    }

    .about {
        margin-top: -20px;
    }

    .container.second{
    /*    hide*/
        display: none;
    }

}