/*BookingSuccessful.css*/
.booking-successful-content {
    width: 80%;
    margin: 0 auto;
}

/*body{*/
/*    background-color: #e8e8e5;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    min-height: 100vh;*/
/*    margin-top: 100px;*/
/*}*/

.booking-successful h1 {
    /*margin: 0 0 8px 0;*/
    /*color: #3080a4;*/
    font-size: 1.5rem;
}

.booking-successful p {
    margin: 4px 0;
    /*color: #555;*/
}

.bold {
    font-weight: bold;
}

.car-image-booked {
    width: 300px;
    margin-right: 20px;
}

.success-footer{
/*    put it at bottom*/
    position: fixed;
    bottom: 0;
}

@media screen and (max-width: 480px) {
    .signup-form, .login-form {
        margin-top: -40px;
        width: 100%;
        height: 80%;
        font-size: 1.5rem;
        padding: 40px;
    }
}
