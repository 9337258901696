/* BookingCard.css */

.booking-card {
    background-color: #d7dbdc;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 20px auto;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    width: 80%;
    display: block;
    flex-direction: row;
    align-items: center;
}

.booking-details {
    flex: 1;
    padding: 10px;
}

.booking-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.booking-card h3 {
    margin: 0 0 8px 0;
    color: #3080a4;
}

.booking-card p {
    margin: 4px 0;
    color: #555;
}

.bold {
    font-weight: bold;
}

.car-image-booked {
    width: 300px;
    margin-right: 20px;
}

.booking-actions button {
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
}

.booking-actions button:first-child {
    background-color: #4e9bbe; /* Color for Edit button */
}

.booking-actions button:last-child {
    color: #fff;
    background-color: #423e7a; /* Color for Cancel button */
}

.booking-actions button:hover {
    background-color: #427036;
    box-shadow: 0 0 10px rgb(61, 54, 110);
}

/* Media query for medium screens (up to 768px) */
@media screen and (max-width: 768px) {
    .booking-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .car-image-booked {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

/* Media query for small screens (up to 480px) */
@media screen and (max-width: 480px) {
    .booking-card {
        width: 90%;
        padding: 10px;
    }

    .booking-card h3 {
        font-size: 18px;
    }

    .booking-card p {
        font-size: 14px;
    }

    .car-image-booked {
        width: 100%;
        height: auto;
    }

    .booking-actions button {
        font-size: 18px;
        padding: 6px 10px;
    }
}
