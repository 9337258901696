/*.contact-container {*/
/*    padding: 50px;*/
/*    width: 50%;*/
/*    margin: 50px auto;*/
/*    text-align: center;*/
/*}*/

.contact-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #008080;
}

.contact-card {
    /*background-color: #f5f5f5; !* Light grey *!*/
    padding: 10px;
    margin: 10px;
    /*border-radius: 15px;*/

    /*box-shadow: 0 0 50px rgba(0, 0, 0, 0.8); !* Add shadow effect *!*/
}

.contact-card p {
    margin: 0;
    font-size: 1.2rem;
}

.contact-card a {
    color: #008080; /* Teal blue */
    text-decoration: none;
}
.contact-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-bottom: 50px;
    padding-top: 40px;
    width: 80%;
    /*margin-top: 20px;*/
    margin: 0 auto; /* Set margin-top to 0 to position from the top */
}

.request-form {
    width: 45%; /* Adjust width for the form */
    max-width: 500px; /* Set maximum width for the form */
    padding: 10px; /* Adjust padding as needed */
    border-radius: 10px;
    /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);*/
    margin-right: 20px; /* Add margin on the right for spacing */
}
/*label and input on different line*/
.request-form label {
    display: block;
    /*margin-bottom: 10px;*/
}

.request-form input {
    width: 80%; /* Subtract padding from width */
    padding: 15px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.request-form button {
    width: 80%; /* Subtract padding from width */
    padding: 15px;
    margin: 10px 0;
    background-color: #26c2be;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


/*textarea bigger*/
textarea {
    width: 80%;
    height: 100px;
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.required {
    color: red;
    font-style: italic;
}

.request-form button:hover {
    background-color: #018370;
}

.contact-details {
    width: 45%; /* Adjust width for the contact details */
    max-width: 500px; /* Set maximum width for the contact details */
}

/* Add remaining styles for contact details as before */


/* Add remaining styles for contact details as before */


/* Responsive Design */
/*@media only screen and (max-width: 768px) {*/


/*    .contact-container {*/
/*        display: block;*/
/*        width: 90%; !* Adjust width for even smaller screens *!*/
/*        padding: 20px; !* Adjust padding for even smaller screens *!*/
/*        margin: 0 auto; !* Adjust margin for even smaller screens *!*/

/*    }*/

/*    .request-form {*/
/*        width: 90%; !* Adjust width for the form *!*/
/*        max-width: 500px; !* Set maximum width for the form *!*/
/*        !*padding: 10px; !* Adjust padding as needed *!*!*/
/*        border-radius: 10px;*/
/*        !*box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);*!*/
/*        !*margin: 10px; !* Add margin on the right for spacing *!*!*/
/*        margin-top: 30px;*/
/*    }*/

/*    .contact-details {*/
/*        width: 90%; !* Adjust width for the contact details *!*/
/*        max-width: 500px; !* Set maximum width for the contact details *!*/
/*        padding: 10px; !* Adjust padding as needed *!*/
/*        !*border-radius: 10px;*!*/
/*        !*box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);*!*/
/*        margin: 5px; !* Add margin on the right for spacing *!*/
/*    }*/

/*    .contact-card {*/
/*        width: 90%; !* Adjust width for even smaller screens *!*/
/*        !*align center*!*/
/*        margin: 10px auto; !* Adjust margin for even smaller screens *!*/

/*        padding: 20px; !* Adjust padding for even smaller screens *!*/

/*        !*margin: 20px; !* Adjust margin for even smaller screens *!*!*/
/*    }*/

/*    button.request-btn {*/
/*        margin-bottom: 40px;*/
/*    }*/


/*    .contact-heading {*/
/*        !*font-size: 1.2rem; !* Adjust font size for even smaller screens *!*!*/
/*        margin-top: -30px; !* Adjust margin for even smaller screens *!*/
/*    }*/

/*    .contact-card p {*/
/*        !*font-size: 0.9rem; !* Adjust font size for even smaller screens *!*!*/
/*        margin: -15px;*/
/*    }*/
/*}*/

@media only screen and (max-width: 480px) {
    .contact-container {
        display: block;
        width: 90%; /* Adjust width for even smaller screens */
        padding: 20px; /* Adjust padding for even smaller screens */
        margin: 5px auto; /* Adjust margin for even smaller screens */

    }

    .request-form {
        width: 90%; /* Adjust width for the form */
        max-width: 500px; /* Set maximum width for the form */
        /*padding: 10px; !* Adjust padding as needed *!*/
        border-radius: 10px;
        /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);*/
        /*margin: 10px; !* Add margin on the right for spacing *!*/
        margin-top: 30px;
    }

    .contact-details {
        width: 90%; /* Adjust width for the contact details */
        max-width: 500px; /* Set maximum width for the contact details */
        padding: 10px; /* Adjust padding as needed */
        /*border-radius: 10px;*/
        /*box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);*/
        margin: 5px; /* Add margin on the right for spacing */
    }

    .contact-card {
        width: 90%; /* Adjust width for even smaller screens */
        /*align center*/
        margin: 10px auto; /* Adjust margin for even smaller screens */

        padding: 20px; /* Adjust padding for even smaller screens */

        /*margin: 20px; !* Adjust margin for even smaller screens *!*/
    }

    button.request-btn {
        margin-bottom: 40px;
    }


    .contact-heading {
        /*font-size: 1.2rem; !* Adjust font size for even smaller screens *!*/
        margin-top: -30px; /* Adjust margin for even smaller screens */
    }

    .contact-card p {
        /*font-size: 0.9rem; !* Adjust font size for even smaller screens *!*/
        margin: -15px;
    }


}
