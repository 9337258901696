/* Profile.css */

.profile-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background-color: #e8e8e5;
    margin-bottom: 30px;
}

.profile-card {
    /*background-color: #e6e9ec;*/
    /*border: 1px solid #ddd;*/
    /*border-radius: 8px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

.profile-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.profile-picture{
    display: block;
    margin: 0 auto;
}

.profile-details{
    margin: 0 auto;
    width: 40%;
}

.profile-details h2 {
    margin: 0;
    margin-bottom: 10px;
    color: #3080a4;
    text-align: left;
}

.profile-details p {
    margin: 0;
    margin-bottom: 4px;
    color: #555;
    text-align: left;
}

.bold {
    font-weight: bold;
    margin-bottom: 5px;
}

.profile-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.profile-actions button {
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.profile-actions button:first-child {
    background-color: #4e9bbe; /* Color for Edit Profile button */
}

.profile-actions button:last-child {
    background-color: #a8c919; /* Color for Logout button */
}

.profile-actions button:hover {
    background-color: #306b8e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

input{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.profile-footer{
    position: fixed;
    bottom: 0;

}


/*for small screen*/
@media only screen and (max-width: 480px) {
    .profile-card {
        /*background-color: #e6e9ec;*/
        /*border: 1px solid #ddd;*/
        /*border-radius: 8px;*/
        display: block;
        justify-content: center;
        align-items: center;
        width: 80%;
        /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    }

    .profile-details{
        margin: 0 auto;
        width: 80%;
    }

    input{
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .profile-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 20px;
    }
}