/*.map-container {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    height: 400px;*/
/*}*/

/*.map-container p {*/
/*    position: absolute;*/
/*    bottom: 10px;*/
/*    left: 10px;*/
/*    background-color: white;*/
/*    padding: 5px;*/
/*    border-radius: 5px;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*    font-size: 14px;*/
/*}*/

/*!* You can add more styles to customize the map appearance *!*/
