/* BookingPage.css */

body {
    background-color: #e8e8e5;
    margin-top: 60px;
}

.booking-page {
    background-color: #e8e8e5;
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /*width: 100%;*/
}

.booking-page h2 {
    margin-bottom: 20px;
    color: #333;
}

.error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

.vehicle-card {
    border: 2px solid transparent;
    padding: 10px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.vehicle-card.selected {
    border-color: #007bff; /* Change to your preferred highlight color */
}

.date-picker,
.time-picker,
.react-select-container,
.input-field,
.textarea,
.button {
    display: block;
    margin: 10px 0;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.step-container {
    /*margin: 0 auto;*/
    padding: 10px;
    /*margin-right: 20px;*/
}

.vehicle-container {
    display: flex;
    justify-content: space-around;
}

.booking-page button {
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    background-color: #189d8f;
    color: white;
    cursor: pointer;
}

.booking-page button:hover {
    background-color: #2fc43c;
}

.booking-page .vehicle-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.booking-page .vehicle-container .vehicle-card {
    width: 45%;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking-page .vehicle-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}

/* Calendar adjustments */
.react-datepicker {
    height: auto; /* Adjust height as needed */
}

/* Align arrows with month and year */
.react-datepicker__navigation {
    top: -17px;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
    .booking-page {
        padding: 10px;
    }

    .booking-page .vehicle-container .vehicle-card {
        width: 80%;
    }

    .booking-page .react-datepicker-wrapper,
    .booking-page .react-select-container {
        width: 90%;
    }
}

/* Media query for screens smaller than 480px */
@media screen and (max-width: 480px) {
    .booking-page .react-datepicker-wrapper,
    .booking-page .react-select-container {
        width: 100%;
    }

    .booking-page button {
        padding: 8px 16px;
        margin-top: 10px;
    }

    .react-datepicker__navigation {
        top: -8px;
    }

    .button.next{
        margin: 0 auto;
        width: 100px;
    }

    .button.confirm{
        margin: 0 auto;
        width: 100px;
    }

    .step-container {
        /*width: 90%;*/
    }

}
