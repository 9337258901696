.footer {
    background-color: #009898; /* TEAL BLUE */
    color: #fff; /* White */
    text-align: center;
    padding: 2px 0; /* Padding for spacing */
    width: 100%;
    margin: 0 auto;
    /*position: fixed;*/
    /*left: 0;*/
    /*bottom: 0;*/

}