/* LoginPage.css */
body {
    background-color: #e8e8e5;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

.login-page img {
    margin-right: 30px; /* Adjust spacing between form and image */
    margin-top: 100px;
    width: 85%;
    height: auto;
}

/* SignUpPage.css */
.signup-page, .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto; /* Center the content horizontally */
}

.signup-form form {
    margin: 0 auto;
    width: 60%;
}

.main-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-page-div {
    padding-bottom: 2.5rem;
    flex: 1;
}

.footer-login-signup {
    width: 100%;
    background: #f1f1f1;
    padding: 20px 0;
    text-align: center;
    margin-top: auto;
}

.signup-container, .login-container {
    display: flex;
    align-items: center;
    padding: 20px;
}

.signup-form, .login-form {
    border-radius: 10px;
    padding-top: 100px;
}

.signup-page img {
    margin-left: 30px; /* Adjust spacing between form and image */
    width: 100%;
    height: auto;
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.al {
    text-decoration: none;
    color: #189d8f;
}

.al:hover {
    color: #2fc43c;
    text-shadow: 1px 1px 1px #114148;
}

/* For screens smaller than 1000px */
@media screen and (max-width: 1000px) {
    .signup-page img, .login-page img {
        display: none;
    }

    .signup-form, .login-form {
        width: 70%;
    }
}

/* For screens between 768px and 1000px */
@media screen and (min-width: 768px) and (max-width: 1000px) {
    .signup-form, .login-form {
        width: 90%;
    }

    .signup-page, .login-page {
        width: 90%;
    }

    .signup-form form, .login-form form {
        width: 90%;
    }
}

/* For screens smaller than 768px */
@media screen and (max-width: 768px) {
    .signup-form, .login-form {
        width: 70%;
    }

    .signup-page, .login-page {
        width: 90%;
    }
}

/* For screens smaller than 480px */
@media screen and (max-width: 480px) {
    .signup-form, .login-form {
        margin-top: -40px;
        width: 100%;
        height: 80%;
        font-size: 1.5rem;
        padding: 40px;
    }

    .signup-page, .login-page {
        height: 100%;
    }

    .signup-form form {
        padding-top: 0;
        margin: 0 auto;
        width: 100%;
    }
}
