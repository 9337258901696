.about-container {
    max-width: 70%;
    margin: 0 auto;
    padding: 30px 20px;

}

.about-container h1 {
    color: #008080; /* TEAL BLUE */
    text-align: center;
}

.about-container h2 {
    color: #70a9a1; /* MOONSTONE BLUE */

}

.about-container p {
    color: #050202; /* WHITE SMOKE */
    line-height: 1.6;
    margin-bottom: 40px;


}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px; /* Gap between rows */
}

.column {
    flex-basis: 48%;
}

.section {
    background-color: #e8e8e5; /* Light gray background color */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
     /* Add shadow effect for left and right */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.highlight, Link {
    color: #008080; /* TEAL BLUE */
    font-weight: bold;
    text-decoration: none;
}

.contactAbout{
    text-decoration: none;
    color: #008080; /* TEAL BLUE */
}

.contactAbout:hover{
    color: #70a9a1; /* MOONSTONE BLUE */
}



/* Responsive Design */
@media only screen and (max-width: 768px) {
    .about-container {
        max-width: 90%; /* Adjust max-width for smaller screens */
        padding: 50px 20px; /* Adjust padding for smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .about-container {
        max-width: 90%; /* Adjust max-width for even smaller screens */
        padding: 30px 10px; /* Adjust padding for even smaller screens */
        margin-top: 30px; /* Adjust margin-top for even smaller screens */
    }

    .column {
        flex-basis: 100%;
    }
}
