/* ManageBookingPage.css */

.manage-booking-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 50px; /* Adjust margin for small screens */
    padding: 10px; /* Adjust padding for small screens */
}

.manage-booking-container h1 {
    font-size: 25px; /* Adjust font size for small screens */
    margin-bottom: 10px; /* Adjust margin for small screens */
}

.booking-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjust margin between booking cards */
}

.car-image-booked {
    width: 200px; /* Adjust width of car image */
    margin-right: 20px; /* Adjust spacing between image and details */
}

.booking-details {
    flex: 1; /* Take remaining space */
}

/* Add CSS for smaller screens as needed */


.booking-table {
    width: 100%;
    border-collapse: collapse;
}

.booking-table th,
.booking-table td {
    border: 1px solid #ddd;
    padding: 6px; /* Adjust padding for small screens */
    text-align: left;
}

.car-image-booked {
    width: 200px; /* Adjust width of car image */
    margin-top: 30px; /* Adjust spacing between car image and booking details */

}

.booking-table th {
    background-color: #f2f2f2;
}

.booking-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.booking-table tr:hover {
    background-color: #f2f2f2;
}

.booking-actions button, .booking-actions-back {
    margin-right: 5px;
    padding: 6px 12px; /* Adjust padding for small screens */
    border: none;
    background-color: #4e9bbe;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add shadow effect */
}

.booking-actions button:hover {
    background-color: #1b587c;
}

.booking-actions-back{
    margin-top: 30px;
    font-size: 18px;
}

.each-booking-footer{
    position: relative;
    bottom: 0;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
    .manage-booking-container {
        margin: 10px; /* Further adjust margin for smaller screens */
        padding: 5px; /* Further adjust padding for smaller screens */
    }

    .manage-booking-container h1 {
        font-size: 18px; /* Further adjust font size for smaller screens */
        margin-bottom: 5px; /* Further adjust margin for smaller screens */
    }

    .booking-table th,
    .booking-table td {
        padding: 4px; /* Further adjust padding for smaller screens */
    }

    .booking-actions button {
        padding: 4px 8px; /* Further adjust padding for smaller screens */
    }
}

/* Media query for extra small screens */
@media only screen and (max-width: 480px) {


    .manage-booking-container{
        margin-top:30px;
    }

    .manage-booking-container h1 {
        font-size: 1.6rem;
        margin-bottom: 5px; /* Further adjust margin for smaller screens */
    }
    .car-image-booked {
        width: 100%; /* Adjust width of car image for smaller screens */
        margin-right: 0; /* Remove margin between image and details for smaller screens */
        margin-bottom: 10px; /* Adjust margin between car image and booking details for smaller screens */
    }

    .booking-details {
        margin-top: 10px; /* Adjust margin between car image and booking details for smaller screens */
    }

    .booking-table th,
    .booking-table td {
        padding: 3px; /* Further adjust padding for smaller screens */
    }

    .booking-actions button {
        padding: 4px 6px; /* Further adjust padding for smaller screens */
    }
}
