.slider-container {
    max-width: 800px;
    margin: 0 auto; /* Center the slider horizontally */
    padding: 20px; /* Adjust as needed */
}

.slider-image {
    max-width: 100%; /* Adjust the width of the images */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.5s ease-in-out;
}

/* Adjustments for small screens */
@media screen and (max-width: 768px) {
    .slider-container {
        /*max-width: 100%; !* Ensure container takes full width *!*/
        width: 100%; /* Ensure container takes full width */
        height: auto;
        /*max-width: none; !* Remove maximum width *!*/
        padding: 0; /* Remove padding */
    }

    /*make sure the image appears full width without cropping*/

    .slider-image {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    .slider-container img{
        display: block;
        width: 370px;
        height: auto;
    }
}

/* Adjustments for extra small screens with min width of 480    */

