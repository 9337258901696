.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.not-found-graphic {
    width: 200px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px;
}

.not-found-heading {
    font-size: 24px;
    margin-bottom: 10px;
}

.not-found-message {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.not-found-suggestion {
    font-size: 14px;
    color: #666; /* Dim text color */
}
