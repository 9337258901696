.service-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 80px;
    background-color: #e8e8e5;
}

.service {
    width: 100%; /* Each service takes the entire width */
    /*margin: 20px;*/
    /*padding: 20px;*/
    border: none;
    /*border-radius: 15px;*/
    background-color: #e0e0e0;
    text-align: center;
    /*//shadow glowing*/
    /*box-shadow: 0 0 40px rgba(0, 0, 0.2, 0.8);*/
}

.service1 {
    /*width: 100%; !* Each service takes the entire width *!*/
    /*margin-top: 10px;*/
    padding: 5px;
    border: none;
    /*border-radius: 15px;*/
    background-color: #e0e0e0;
    text-align: center;
    /*//shadow glowing*/
    /*box-shadow: 0 0 40px rgba(0, 0, 0.2, 0.8);*/
}

.service2 {
    /*width: 100%; !* Each service takes the entire width *!*/
    /*margin-bottom: 20px;*/
    padding: 5px;
    border: none;
    /*border-radius: 15px;*/
    background-color: #e8e8e5;
    text-align: center;
    /*//shadow glowing*/
    /*box-shadow: 0 0 40px rgba(0, 0, 0.2, 0.8);*/
}

.service-intro {
    margin-bottom: 20px;
    max-width: 80%;
}

h1 {
    color: #008080;
}

.service h2 {
    color: #333;
}

.service p {
    color: #4b4a4a;
    font-size: 1.2rem;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #26c2be;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #308e51;
}

.service-page img {
    width: 200px;
    height: auto;
}




