/* Navbar.css */

.navbar {
    background-color: #dededc;
    padding: 20px 20px; /* Adjust padding for smaller screens */
    width: 100%;
    margin: 0;
    font-family: Laila, sans-serif;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px; /* Adjust padding for smaller screens */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: #3080a4;
    font-family: Laila, sans-serif;
    font-size: 30px;
    margin: 0;
    text-decoration: none;
}

.logo:hover {
    text-decoration: none;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.nav-links li {
    margin-right: 30px; /* Adjust margin between links */
/*    bold lightly*/
    font-weight: 0.7;

}

.nav-links li:last-child {
    margin-right: 0;
}

.nav-links a {
    color: #3080a4;
    text-decoration: none;
    font-size: 20px;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #306b8e;
/*    shadow for text darker and glowing*/
    text-shadow: 1px 1px 1px #114148;
}

.login-signup {
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.login-signup .signup {
    background-color: #713291;
}

.login-signup .login {
    background-color: #a8c919;
}

.login-signup .btn {
    color: #fff;
    border: none;
    padding: 8px 16px; /* Adjust padding for smaller screens */
    border-radius: 5px;
    font-size: 14px; /* Adjust font size for smaller screens */
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-left: 10px; /* Add margin between buttons */
}

/*btn logout*/

.login-signup .btn:hover {
    background-color: #306b8e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Media query for smaller screens */
@media screen and (max-width: 1000px) {
    .container {
        margin: 0 auto;
        padding: 0 10px;
    }

    .logo {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .menu-icon {
        display: block;
        font-size: 24px;
        cursor: pointer;
        border: none; /* Remove border */
        background-color: transparent; /* Remove background */
        position: fixed;
        top: 20px; /* Adjust top position */
        right: 20px; /* Adjust right position */
        z-index: 1001; /* Ensure menu button appears above other content */
        margin-top: 5px;
        margin-right: -15px;

    }

    .nav-links {
        display: none; /* Hide navigation links by default on smaller screens */
        flex-direction: column; /* Stack links vertically */
        position: absolute;
        top: 70px; /* Adjust top position to avoid overlapping */
        right: 55px; /* Adjust right position */
        background-color: #d2d0d0;
        width: auto; /* Adjust width as needed */
        text-align: center;
        z-index: 999; /* Ensure menu appears above other content */
    }

    .nav-links.active {
        display: flex; /* Display links when navbar is active */
    }

    .nav-links li {
        margin: 10px 0; /* Add margin between links */
    }

    .login-signup {
        display: flex;
        /* Align login/signup to the left */
        justify-content: flex-start;

    }
}


/* Media query for larger screens */
@media screen and (min-width: 1001px) {
    .menu-icon {
        display: none; /* Hide menu icon on larger screens */
    }

    .nav-links {
        display: flex !important; /* Ensure navigation links are visible */
    }

/*    put login and signup to the right*/
    .login-signup {
        display: flex;
        justify-content: flex-end;
    }
}


/*for480px*/
@media only screen and (max-width: 480px) {
    .container {
        margin: 0 auto;
        padding: 0 10px;
    }

    /*.logo {*/
    /*    font-size: 20px; !* Adjust font size for smaller screens *!*/
    /*}*/

    .menu-icon {
        display: block;
        font-size: 24px;
        cursor: pointer;
        border: none; /* Remove border */
        background-color: transparent; /* Remove background */
        top: 10px; /* Adjust top position */
        right: 30px; /* Adjust right position */
        z-index: 1001; /* Ensure menu button appears above other content */
        /*margin-top: 5px;*/
        /*margin-right: -15px;*/

    }

    .nav-links {
        display: none; /* Hide navigation links by default on smaller screens */
        flex-direction: column; /* Stack links vertically */
        position: absolute;
        top: 70px; /* Adjust top position to avoid overlapping */
        right: 55px; /* Adjust right position */
        background-color: #d2d0d0;
        width: auto; /* Adjust width as needed */
        text-align: center;
        z-index: 999; /* Ensure menu appears above other content */
        padding: 0 30px;
    }

    .nav-links.active {
        display: flex; /* Display links when navbar is active */
    }

    .nav-links li {
        margin: 10px 0; /* Add margin between links */
    }

    .login-signup {
        display: flex;
        /* Align login/signup to the left */
        justify-content: flex-start;
        margin-right: 30px;

    }

    .btn.signup{
        margin-right: 10px;
    }

    .logo{
        margin-left: -30px;
    }
}